
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';
import {arrowBack, scanOutline} from 'ionicons/icons';
import {computed, getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import PhotoUploader from "@/views/component/PhotoUploader.vue";
import VideoUploader from "@/views/component/VideoUploader.vue";
import {doEdit, getLastReport} from "@/api/vehicleclean"
import {UIUtils} from "@/common/UIUtils";
import {VehicleCleanVO} from "@/vo/VehicleCleanVO";
import VehicleSelectionModal from "@/views/component/VehicleSelectionModal.vue"
import ClearableSelector from "@/views/component/ClearableSelector.vue"
import {VehicleInfoVO} from "@/vo/VehicleInfoVO";
import {BarcodeScanner, BarcodeScanResult} from "@awesome-cordova-plugins/barcode-scanner";
import {format, parse} from "date-fns";
import {Constants} from "@/common/Constants";
import {Filters} from "@/common/Filters";
import {ComboInfo} from "@/vo/ComboInfo";
import {ArrayUtils} from "@/common/ArrayUtils";
import {QRCodeVO} from "@/vo/QRCodeVO";

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonTextarea,
    IonInput,
    IonLabel,
    IonButtons,
    IonButton,
    IonIcon,
    IonCol,
    IonRow,
    IonGrid,
    IonFooter,
    IonAccordion,
    IonAccordionGroup,
    IonSelect,
    IonSelectOption,
    IonImg,
    VehicleSelectionModal,
    PhotoUploader,
    VideoUploader,
    ClearableSelector
  }
  ,
  props: {
    params: {
      type: Object
    },
    modalId: {
      type: String
    },
  }
  ,
  setup(props: any) {
    console.log("props.modalId = " + props.modalId)
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    let temp: {
      cleanTimeString: string,
      form: VehicleCleanVO,
      vehicleSelectionOpen: boolean,
      disinfectorNameList: Array<ComboInfo>,
      disinfectorPercentList: Array<ComboInfo>,
      cleanLocationList: Array<ComboInfo>,
      cleanTypeList: Array<ComboInfo>,
      cleanLocationSelectionIds: Array<number>,
      event?: Event,
    } = {
      cleanTimeString: (props.params && props.params.item) ? format(new Date(props.params.item.cleanTime), 'yyyy-MM-dd HH:mm') : format(new Date(), 'yyyy-MM-dd HH:mm'),
      form: (props.params && props.params.item) ? props.params.item : {},
      vehicleSelectionOpen: false,
      disinfectorNameList: store.getters["dic/disinfectorNameList"],
      disinfectorPercentList: store.getters["dic/disinfectorPercentList"],
      cleanLocationList: store.getters["dic/cleanLocationList"],
      cleanTypeList: store.getters["dic/cleanTypeList"],
      cleanLocationSelectionIds: (props.params && props.params.item) ? JSON.parse(props.params.item.cleanLocationIds) : [],
      event: undefined,
    }
    const state = reactive(temp);

    const disinfectorNamePopoverOptions: any = {
      header: '消毒剂选择',
      // subHeader: '选择消毒剂种类',
      // message: '请选择一种消毒剂'
    };

    const back = () => {
      console.log("back")
      UIUtils.dismissModal(props.modalId);
    }

    const openVehicleSelection = (item: VehicleCleanVO) => {
      if (!props.params.viewFlag) {
        state.vehicleSelectionOpen = true;
      }
    }

    const save = async (flag: number) => {
      const msg = validation()
      if (msg) {
        UIUtils.showToast("", msg, 2000)
        return
      }

      let confirmMsg = ""
      if (flag === 0) {
        confirmMsg = "是否确认开始消毒?"
      } else if (flag === 1) {
        confirmMsg = "是否确认提交消毒数据?"
      }

      UIUtils.showAlertConfirm("确认", confirmMsg, async () => {
        const date = parse(state.cleanTimeString, 'yyyy-MM-dd HH:mm', new Date())
        state.form.cleanTime = date.getTime()
        const originValue = state.form.status
        if (flag === 0) {
          state.form.status = Constants.CleanStatus.BEGIN
        } else if (flag === 1) {
          state.form.status = Constants.CleanStatus.COMMIT
        }

        const disinfectorItem: ComboInfo = ArrayUtils.findItem(state.disinfectorNameList, "value", state.form.vehicleDisinfectorId)
        const disinfectorPercentItem: ComboInfo = ArrayUtils.findItem(state.disinfectorPercentList, "value", state.form.vehicleDisinfectorPercentId)
        const cleanLocationItems: Array<ComboInfo> = state.cleanLocationSelectionIds.map(id => ArrayUtils.findItem(state.cleanLocationList, "value", id))

        state.form.vehicleDisinfectorName = disinfectorItem ? disinfectorItem.label : undefined
        state.form.vehicleDisinfectorPercentName = disinfectorPercentItem ? disinfectorPercentItem.label : undefined
        state.form.radioDisinfectorName = disinfectorItem ? disinfectorItem.label : undefined
        state.form.radioDisinfectorPercentName = disinfectorPercentItem ? disinfectorPercentItem.label : undefined
        state.form.cleanLocationIds = cleanLocationItems && cleanLocationItems.length > 0 ? JSON.stringify(cleanLocationItems.map(item => item.value)) : undefined
        state.form.cleanLocationNames = cleanLocationItems && cleanLocationItems.length > 0 ? JSON.stringify(cleanLocationItems.map(item => item.label)) : undefined

        state.form.pictureInfo = JSON.stringify(state.form.pictureList)
        state.form.videoInfo = JSON.stringify(state.form.videoList)

        try {
          await doEdit(state.form)
          back()
        } catch (e) {
          console.error(e)
          UIUtils.showToast("", "保存数据失败", 2000)
          state.form.status = originValue
        }
      }, () => {
        console.log("取消")
      }, "提交", "取消")
    }

    const vehicleSelectionClose = async (item: VehicleInfoVO) => {
      console.log("vehicleSelectionClose", item)
      if (item) {
        await afterVehicleSelect({id: item.id, name: item.carNumber})
      }
      state.vehicleSelectionOpen = false
    }

    const afterVehicleSelect = async (item: { id: string, name: string }) => {
      if (item) {
        if (fillVehicleCleanInfo(item)) {
          const lastReport = await getLastReport(item.name)
          if (lastReport) {
            state.form = Object.assign({}, lastReport)
            state.form.id = undefined
            state.cleanLocationSelectionIds = state.form.cleanLocationIds ? JSON.parse(state.form.cleanLocationIds) : []
            state.form.status = undefined
            state.form.pictureList = []
            state.form.videoList = []
            state.form.beginTime = undefined
            state.form.commitTime = undefined
            state.form.signatureDataUrl = undefined
          } else {
            console.warn("没有上一次上报信息")
          }
        }
      }
    }

    const fillVehicleCleanInfo = (vehicle: { id: string, name: string }) => {
      const vehicleList: Array<VehicleInfoVO> = store.getters["selector/vehicleList"]
      if (vehicleList && vehicleList.length > 0 && vehicleList.some(item => item.id === vehicle.id && item.carNumber === vehicle.name)) {
        state.form.vehiclePlate = vehicle.name
        return true
      } else {
        UIUtils.showToast("", "请与管理员确认车牌号是否正确", 2000)
        state.form.vehiclePlate = undefined
        return false
      }
    }

    const openQRScan = () => {
      BarcodeScanner.scan().then(async (barcodeData: BarcodeScanResult) => {
        console.log('Barcode data', barcodeData);
        try {
          const vehicle: QRCodeVO = JSON.parse(barcodeData.text)
          if (vehicle && vehicle.id && vehicle.name) {
            await afterVehicleSelect(vehicle)
          } else {
            throw new Error("二维码无效")
          }
        } catch (e) {
          UIUtils.showToast("", "二维码无效, 请确认您有消毒权限", 2000)
        }

      }).catch(err => {
        console.log('Error', err);
      });
    }

    const validation = () => {
      if (!state.form.flightType) {
        return "航班类型不允许为空";
      }
      if (state.form.flightType === Constants.FlightType[1] && !state.form.flightNo) {
        return "航班号不允许为空";
      }
      if (!state.form.vehiclePlate || state.form.vehiclePlate.trim() == '') {
        return "消毒车辆不允许为空";
      }
      if (state.form.flightType === Constants.FlightType[0]) {
        if (!state.form.vehicleDisinfectorId) {
          return "消毒剂名称不允许为空";
        }
        if (!state.form.vehicleDisinfectorPercentId) {
          return "消毒剂配比不允许为空";
        }
        if (!state.form.vehicleCleanType || state.form.vehicleCleanType.trim() == '') {
          return "消毒方式不允许为空";
        }
      } else if (state.form.flightType === Constants.FlightType[1]) {
        // if (!state.form.radioDisinfectorId) {
        //   return "消毒剂名称不允许为空";
        // }
        // if (!state.form.radioDisinfectorPercentId) {
        //   return "消毒剂配比不允许为空";
        // }
        // if (!state.form.radioCleanType || state.form.radioCleanType.trim() == '') {
        //   return "消毒方式不允许为空";
        // }
      }

      if (!state.cleanTimeString || state.cleanTimeString.trim() == '') {
        return "消毒时间不允许为空";
      }
      if (!state.form.cleanTool || state.form.cleanTool.trim() == '') {
        return "消毒工具不允许为空";
      }
      return null;
    }

    const reportTypeCalc = computed(() => {
      if (state.form.flightType === Constants.FlightType[0]) {
        return 1
      } else if (state.form.flightType === Constants.FlightType[1]) {
        return 2
      }
      return 0;
    })
    const rejectedCalc = computed(() => {
      return state.form.status === Constants.CleanStatus.REJECT
    })

    onMounted(() => {
      if (props.params && props.params.item) {
        console.log("不打开二维码扫描")
      } else {
        openQRScan()
      }
    })
    return {
      ...toRefs(state),
      scanOutline,
      arrowBack,
      back,
      save,
      openVehicleSelection,
      vehicleSelectionClose,
      openQRScan,
      rejectedCalc,
      CleanStatus: Constants.CleanStatus,
      timeFormatCalc: Filters.timeFormatCalc,
      cleanStatusCreateCalc: Filters.cleanStatusCreateCalc,
      disinfectorNamePopoverOptions,
      FlightTypeList: Constants.FlightType,
      reportTypeCalc,
    }
  }
}
