
import {IonCol, IonGrid, IonImg, IonRow} from '@ionic/vue';
import {computed, getCurrentInstance, reactive, toRefs, watch} from "vue";
import {uuid} from 'vue-uuid';
import {PictureInfoType} from "@/common/TypeDefs";
import {UIUtils} from "@/common/UIUtils";
import {upload, uploadDataUrl} from "@/api/upload"
import {PhotoViewer} from "@awesome-cordova-plugins/photo-viewer";
import {Capacitor} from "@capacitor/core";

export default {
  name: 'PhotoUploder',
  components: {IonGrid, IonImg, IonRow, IonCol},
  props: {
    title: {type: String},
    subTitle: {type: String},
    required: {type: Boolean},
    modelValue: {type: Array, default: () => []},
    viewFlag: {type: Boolean},
  },
  emits: ['onClick', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()

    let temp: {
      pictureList: Array<PictureInfoType>,
      disablePreview: boolean,
      event?: Event,
    } = {
      pictureList: props.modelValue ? (typeof props.modelValue === 'string' ? JSON.parse(props.modelValue) : props.modelValue) : [],
      disablePreview: false,
      event: undefined,
    }
    const state = reactive(temp);

    const onClick = () => {
      emit('onClick', props.data);
    }

    const removePic = (index: number, event?: Event) => {
      try {
        console.info("dblclick");
        state.disablePreview = true;
        state.pictureList.splice(index, 1);
        emit('update:modelValue', state.pictureList);
        if (event) {
          event.stopPropagation();
          event.cancelBubble = true;
        }
      } finally {
        setTimeout(() => {
          state.disablePreview = false;
        }, 1000);
      }
    }

    const takePicture = async (jobId: string) => {
      let fileUrls = null
      try {
        fileUrls = await UIUtils.showPicturePick();
      } catch (e) {
        UIUtils.showToast("错误", "照片选择失败", 2000)
        return null;
      }

      const dissmissFunc = await UIUtils.showLoading("正在上传照片");
      try {
        const convertedUrls = fileUrls;//fileUrls.map(fileUrl => Capacitor.convertFileSrc(fileUrl));
        let failCount = 0;
        let successItems = [];
        for (let i = 0; i < convertedUrls.length; i++) {
          const id = uuid.v4();
          try {
            let result = null;
            if (Capacitor.getPlatform() === 'web') {
              result = await uploadDataUrl(id, jobId, convertedUrls[i]);
            } else {
              result = await upload(id, jobId, convertedUrls[i]);
            }
            console.info("picture upload result = ", result);
            if (result) {
              successItems.push({id: result.id, name: 'pic', url: result.url});
            }
          } catch (e) {
            console.error("上传照片" + convertedUrls[i] + "失败", e);
            failCount++;
          }
        }

        if (failCount > 0) {
          UIUtils.showToast("", "共计" + failCount + "张照片上传失败", 2000);
        } else {
          // UIUtils.showToast("", "照片上传成功", 2000);
        }

        state.pictureList.push(...successItems);
        console.info(state.pictureList);
        emit('update:modelValue', state.pictureList);
      } finally {
        dissmissFunc();
      }
    }

    const previewImage = (url: string) => {
      if (Capacitor.getPlatform() === 'web') {
        window.open(url, '_blank')
      } else {
        setTimeout(() => {
          if (!state.disablePreview) {
            console.info("previewImage, url = " + url);
            PhotoViewer.show(url, '', {
              share: true,
              closeButton: true,
              copyToReference: false,
              headers: "",
              piccasoOptions: {}
            });
          }
        }, 500);
      }
    }

    const picFirstLine = computed(() => {
      if (state.pictureList.length > 3) {
        return state.pictureList.slice(0, 3);
      } else {
        return state.pictureList;
      }
    })

    const picOtherLine = computed(() => {
      return (line: number) => {
        console.info(line);
        if (state.pictureList.length > 3 + 4 * line) {
          if (state.pictureList.length < 3 + 4 * (line + 1)) {
            return state.pictureList.slice(3 + 4 * line, state.pictureList.length);
          } else {
            return state.pictureList.slice(3 + 4 * line, 3 + 4 * (line + 1));
          }
        } else {
          return [];
        }
      }
    })

    const picLineNum = computed(() => {
      if (state.pictureList.length > 3) {
        const indices = [];
        for (let i = 0; i < (state.pictureList.length - 3) / 4 + 1; i++) {
          indices.push(i);
        }
        return indices;
      } else {
        return [];
      }
    })

    emit('update:modelValue', state.pictureList);

    watch(() => props.modelValue, (n, o) => {
      state.pictureList = n ? (typeof n === 'string' ? JSON.parse(n) : n) : []
    })

    return {
      ...toRefs(state),
      onClick,
      takePicture,
      removePic,
      previewImage,
      picLineNum,
      picFirstLine,
      picOtherLine,
    }
  }
}
