
import {IonAccordion, IonAccordionGroup, IonContent, IonInput, IonItem, IonLabel, IonList, IonTextarea} from '@ionic/vue';
import {arrowBack, scanOutline} from 'ionicons/icons';
import {computed, getCurrentInstance, onMounted, reactive, toRefs, watch} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import PhotoUploader from "@/views/component/PhotoUploader.vue";
import {Filters} from "@/common/Filters";
import {NucleicTemperInfo} from "@/vo/NucleicTemperVO";
import {DateUtils} from "@/common/DateUtils";
import {PictureInfoType} from "@/common/TypeDefs";
import {ReportDefItemWithValue} from "@/vo/ReportDefItemWithValue";
import DynamicForm from "@/views/component/DynamicForm.vue";
import {PositionRes} from "@awesome-cordova-plugins/gao-de-location";

export default {
  components: {
    IonContent,
    IonList,
    IonItem,
    IonTextarea,
    IonInput,
    IonLabel,
    IonAccordion,
    IonAccordionGroup,
    PhotoUploader,
    DynamicForm
  }
  ,
  props: {
    item: Object
  },
  setup(props: any) {
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    let temp: {
      pageItems: Array<ReportDefItemWithValue>,
      form: NucleicTemperInfo,
      detectTimeString?: string,
      detectResultFlag: boolean,
      unDetectReasonFlag: boolean,
      detectImgFlag: boolean,
      detectImgArray: Array<PictureInfoType>,
      notDetectRemarkRef: any,
      event?: Event,
    } = {
      pageItems: [],
      form: (props.item) ? props.item : {},
      detectTimeString: undefined,
      detectResultFlag: false,
      detectImgFlag: false,
      unDetectReasonFlag: true,
      detectImgArray: (props.item && props.item.detectImg ? JSON.parse(props.item.detectImg) : []),
      notDetectRemarkRef: null,
      event: undefined,
    }

    const state = reactive(temp);

    const back = () => {
      console.log("back")
    }

    const refreshFlags = (value: string) => {
      if (value === '未检测') {
        state.form.detectDate = undefined
        state.detectTimeString = undefined
        state.form.detectResult = undefined

        state.detectResultFlag = true
        state.unDetectReasonFlag = false
        state.detectImgFlag = true
      } else {
        // state.form.detectDate = DateUtils.getLastDay().getTime()
        if (state.form.detectDate != null) {
          state.detectTimeString = DateUtils.fromTs(state.form.detectDate, 'yyyy-MM-dd')
        }
        state.detectResultFlag = false
        state.unDetectReasonFlag = true
        state.detectImgFlag = false
      }
    }
    const detectTypeChange = (event: CustomEvent) => {
      refreshFlags(event.detail.value)
    }

    // const refreshReportExt = async () => {
    //   const id = state.form.id
    //   getReportItems(id, true).then(async (items) => {
    //     state.pageItems = items
    //     await nextTick()
    //     await proxy.$refs['dynamicFormId'].refreshDic()
    //   }).catch((err) => {
    //     console.error(err)
    //     UIUtils.showToast("", "获取页面配置失败", 2000)
    //   })
    // }

    const onLocation = (location: PositionRes) => {
      state.form.address = location.formattedAddress
      state.form.province = location.province
      state.form.city = location.city
      state.form.area = location.district
      state.form.street = location.street
      state.form.latitude = location.latitude
      state.form.longitude = location.longitude
    }

    const lastReportCalc = computed(() => {
      if (state.form.lastDate) {
        if (state.form.detectDate) {
          return state.form.lastDate + '(' + state.form.lastDetectResult + ')' + ', 距本次' + state.form.lastDays + '天'
        } else {
          return state.form.lastDate + '(' + state.form.lastDetectResult + ')' + ', 距今' + state.form.lastDays + '天'
        }
      } else {
        return '未找到上次核酸信息'
      }
    })

    watch(() => props.item, (n, o) => {
      console.log(n, o)
      state.form = n ? n : {}
      state.detectImgArray = (n && n.detectImg ? JSON.parse(n.detectImg) : [])
    })

    onMounted(() => {
      const intervallId = setInterval(async () => {
        //感觉是ionic的bug, textarea-wrapper的高度设置被设置为nativeInput的scrollHeight的高度, 但是scrollHeight高度为0, 导致控件显示不出来
        if (state.notDetectRemarkRef) {
          const textarea: HTMLTextAreaElement = await state.notDetectRemarkRef.$el.getInputElement()
          if (textarea) {
            if (textarea.parentElement) {
              textarea.parentElement.style.height = "auto"
            }
            textarea.style.height = "auto"
            console.log(textarea)

            clearInterval(intervallId)
          }
        }
      }, 1000)

      // refreshReportExt()
    })

    refreshFlags(state.form.detectType)

    return {
      ...toRefs(state),
      scanOutline,
      arrowBack,
      back,
      detectTypeChange,
      onLocation,
      lastReportCalc,
      detectTypeList: store.getters["dic/detectTypeList"],
      detectResultList: store.getters["dic/detectResultList"],
      notDetectReasonList: store.getters["dic/notDetectReasonList"],
      timeFormatCalc: Filters.timeFormatCalc,
    }
  }
}
