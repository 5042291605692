import axios from "@/common/AxiosExt";
import {API} from "./config";
import {GridResult} from "@/vo/GridResult";
import {NucleicTemperInfo} from "@/vo/NucleicTemperVO";
import {NucleicAuditListVO} from "@/vo/NucleicAuditListVO";
import {AuditStatisticsDataVO} from "@/vo/AuditStatisticsDataVO";
import {ReportDefItemWithValue} from "@/vo/ReportDefItemWithValue";
import {BadgeItem} from "@/common/TypeDefs";

export async function getList(pageNo: number, pageSize: number, type?: number) {
    const data: GridResult<NucleicTemperInfo> = await axios.get(API.GET_NUCLEIC_LIST, {params: {pageNo, pageSize, type}});
    return data;
}

export async function getLastNucleicTemper() {
    const data: NucleicTemperInfo = await axios.get(API.GET_LAST_NUCLEIC_TEMPER);
    return data;
}

export async function getReportItems(id: string, viewFlag?: boolean) {
    const data: Array<ReportDefItemWithValue> = await axios.get(API.NUCLEIC_REPORT_ITEMS, {params: {id, viewFlag}});
    return data;
}

export async function doEdit(vo: NucleicTemperInfo) {
    await axios.post(API.SAVE_NUCLEIC, vo);
}


export async function doDelete(ids: Array<string>) {
    await axios.post(API.DELETE_NUCLEIC, ids);
}

export async function getAuditList(type: number) {
    const data: NucleicAuditListVO = await axios.get(API.GET_NUCLEIC_AUDIT_LIST, {params: {type}});
    return data;
}

export async function getAuditChildList(deptId: string, auditDeptId: string, type: number) {
    const data: NucleicAuditListVO = await axios.get(API.GET_NUCLEIC_AUDIT_CHILD_LIST, {params: {deptId, auditDeptId, type}});
    return data;
}

export async function doTemperAuditList(id: string, rejectFlag: boolean, rejectReason?: string) {
    await axios.get(API.GET_NUCLEIC_DO_TEMPER_AUDIT, {params: {id, rejectFlag, rejectReason}});
}

export async function doAuditCommit(deptId: string, auditDeptId: string, ignoreNotReport: boolean, remark?: string) {
    await axios.get(API.GET_NUCLEIC_DO_AUDIT_COMMIT, {params: {deptId, auditDeptId, ignoreNotReport, remark}});
}

export async function getStatsData(deptId: string) {
    const data: AuditStatisticsDataVO = await axios.get(API.GET_NUCLEIC_STATS_DATA, {params: {deptId}});
    return data
}

export async function searchByUser(searchText: string) {
    const data: Array<NucleicTemperInfo> = await axios.get(API.SEARCH_NUCLEIC_BY_USER, {headers: {noloading: '1'}, params: {searchText}});
    return data
}


export async function getAuditCount(noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: BadgeItem = await axios.get(API.GET_NUCLEIC_AUDIT_COUNT, {headers});
    return data;
}

export async function getAuditChildCount(deptId: string, auditDeptId: string, noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: NucleicAuditListVO = await axios.get(API.GET_NUCLEIC_AUDIT_CHILD_COUNT, {params: {deptId, auditDeptId}});
    return data;
}
