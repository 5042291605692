
import {IonApp, IonRouterOutlet, useIonRouter} from '@ionic/vue';
import {defineComponent} from 'vue';
import {App} from "@capacitor/app";
export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup() {
    const ionRouter = useIonRouter();
    console.log("App setup");

    App.addListener('backButton', ({canGoBack}) => {
      if (!canGoBack) {
        App.exitApp();
      } else {
        window.history.back();
      }
    });
  }
});
