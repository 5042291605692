
import {IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, onIonViewDidEnter} from '@ionic/vue';
import {chevronDownCircleOutline, ellipse, square, triangle} from 'ionicons/icons';
import MenuItem from '@/views/component/MenuItem.vue';
import {computed, onMounted, reactive, toRefs, watch} from "vue";
import {NaviUtils} from "@/common/NaviUtils";
import {getSystemUser} from "@/api/login";
import {useStore} from "vuex";
import {RefresherCustomEvent} from "@ionic/core/dist/types/components/refresher/refresher-interface";
import {checkUpdate, getHomeBadge, registToken} from "@/api/common";
import {Runtimes} from "@/common/Runtimes";
import {GeoUtils} from "@/common/GeoUtils";
import {UIUtils} from "@/common/UIUtils";
import {FileOpener} from "@awesome-cordova-plugins/file-opener";
import {App} from "@capacitor/app";
import {FileTransfer, FileTransferObject} from "@awesome-cordova-plugins/file-transfer";
import {File} from "@awesome-cordova-plugins/file";
import {UmengPush} from "../../../capacitor-plugins/umeng-push/src";
import {Env} from "@/common/Env";
import {AppMenu} from "@/vo/AppMenu";
import {UserInfo} from "@/vo/UserInfo";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    MenuItem
  },
  setup() {
    const store = useStore()
    let temp: {
      menuItems: Array<AppMenu>,
      badgeNumList: Array<number>,
      user: UserInfo,
      event?: Event,
    } = {
      menuItems: store.getters["user/userMenu"],
      badgeNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      user: store.getters["user/userInfo"],
      event: undefined,
    }

    const state = reactive(temp)

    const gotoPage = (url: string) => {
      console.log(url)
      NaviUtils.navPush(url)
    }

    const userNameCalc = computed(() => {
      const userName = store.getters["user/userName"];
      console.info("userName = " + userName);
      return userName;
    })

    const statusNameCalc = computed(() => {
      const userInOutStatus = store.getters["user/userInOutStatus"];
      console.info("userInOutStatus = " + userInOutStatus);
      return userInOutStatus;
    })

    const menuItemcalc = computed(() => {
      const items: Array<AppMenu> = []
      items.push(...state.menuItems)
      items.push(...[{title: '密码管理', icon: Env.basePath + 'assets/home/password.png', path: '/password', badgeNum: 0, id: 'password'},
        {title: '消息管理', icon: Env.basePath + 'assets/home/messages.png', path: '', badgeNum: 0, id: 'message'},
        {title: '退出', icon: Env.basePath + 'assets/home/quit.png', path: '/logout', badgeNum: 0, id: 'quit'},])

      for (let i = 0; i < items.length; i++) {
        items[i].badgeNum = state.badgeNumList[i]
      }
      return items
    })

    const doRegistToken = async () => {
      try {
        const {token} = await UmengPush.getDeviceToken()
        const user = store.getters['user/userInfo']
        if (user) {
          await registToken({platform: Runtimes.appInfo.platform, token})
        } else {
          console.warn("用户不存在")
        }
        console.info("getDeviceToken", token)
      } catch (e) {
        console.error(e)
      }
    }

    const doRefresh = (event?: RefresherCustomEvent) => {
      getHomeBadge(true).then((result) => {
        for (let i = 0; i < state.menuItems.length; i++) {
          const menuItem = state.menuItems[i]
          for (let id in result) {
            if (menuItem.id === id) {
              state.badgeNumList[i] = result[id]
              console.log(menuItem.title + " = " + result[id])
            }
          }
        }
      }).finally(() => {
        if (event) {
          event.target.complete()
        }
      })
    }

    setInterval(() => {
      doRefresh()
      checkUpdate(true)
    }, 60000)

    watch(() => store.getters["user/userInfo"], (n, o) => {
      state.user = n
    })

    onIonViewDidEnter(async () => {
      state.menuItems = store.getters['user/userMenu'];

      doRefresh()
      doRegistToken()

      //加载基本信息
      store.dispatch('selector/getBasicDataList');
      //加载字典表信息
      store.dispatch('dic/getDicInfos');

      if (Runtimes.needModifyPwd) {
        UIUtils.showToast("", "当前账户需要修改密码", 1500);
        NaviUtils.navPush("/password");
      }
    })

    onMounted(async () => {
      try {
        const user = await getSystemUser()
        await store.dispatch('user/getUserInfo')
        Runtimes.needModifyPwd = user.initPwd

        //加载菜单
        state.menuItems = await store.dispatch('user/getUserMenu');

        doRefresh()
        doRegistToken()

        if (Runtimes.needModifyPwd) {
          UIUtils.showToast("", "当前账户需要修改密码", 1500);
          NaviUtils.navPush("/password");
        }
        const position = await GeoUtils.getCurrentPosition();
        console.info("user = ", user);
        console.info("location = ", position);
      } catch (e) {
        console.error(e)
      }

      try {
        const updateInfo = await checkUpdate(true)
        if (updateInfo) {
          await UIUtils.showAlertConfirm("检测到更新", "新版本【" + updateInfo.versionStr + "】, 更新内容: " + updateInfo.description,
              async () => {
                let dismissFunc: any
                try {
                  dismissFunc = await UIUtils.showLoading("正在下载更新")
                  const fileTransObj: FileTransferObject = FileTransfer.create()
                  await fileTransObj.download(updateInfo.installPath, File.cacheDirectory + "update.apk")
                  await FileOpener.open(File.cacheDirectory + "update.apk", updateInfo.mime)
                  App.exitApp()
                } catch (e) {
                  console.error(e)
                } finally {
                  if (dismissFunc) {
                    dismissFunc()
                  }
                }
              }, () => {
                console.log("取消更新")
                if (updateInfo.forceUpdate === 1) {
                  App.exitApp()
                } else {
                  console.log("关闭窗口")
                }
              }, "更新", updateInfo.forceUpdate === 1 ? "退出" : "取消", false)
        }
      } catch (e) {
        console.error(e)
      }
    })
    return {
      ...toRefs(state),
      ellipse,
      square,
      triangle,
      chevronDownCircleOutline,
      gotoPage,
      userNameCalc,
      statusNameCalc,
      menuItemcalc,
      doRefresh,
      basePath: Env.basePath,
    }
  }
}
