import {DesiredAccuracyEnum, GaoDeLocation, LocationModeEnum, LocationProtocolEnum, PositionRes} from "@awesome-cordova-plugins/gao-de-location";
import {regeo} from "@/api/common";
import {Capacitor} from "@capacitor/core";

export class GeoUtils {
    public static async getCurrentPosition() {
        if (Capacitor.getPlatform() === 'web' && navigator.geolocation) {
            return new Promise<PositionRes>((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(async (position: GeolocationPosition) => {
                        const regeoCode = await regeo([{longitude: position.coords.longitude.toString(), latitude: position.coords.latitude.toString()}])
                        resolve({
                            code: 0,
                            latitude: position.coords.latitude.toString(),
                            longitude: position.coords.longitude.toString(),
                            accuracy: '',
                            formattedAddress: regeoCode.address,
                            country: regeoCode.country,
                            province: regeoCode.province,
                            city: regeoCode.city,
                            district: regeoCode.area,
                            citycode: regeoCode.cityCode,
                            adcode: '',
                            street: regeoCode.street,
                            number: '',
                            POIName: '',
                            AOIName: '',
                            altitude: position.coords.altitude ? position.coords.altitude.toString() : undefined,
                        })
                    }
                    , (positionError: GeolocationPositionError) => {
                        console.error(positionError)
                        reject(new Error(positionError.message))
                    }
                    , {
                        enableHighAccuracy: true
                        , timeout: 10000
                        , maximumAge: 0
                    }
                );
            })
        } else {
            return await GaoDeLocation.getCurrentPosition({
                androidOption: {
                    locationMode: LocationModeEnum.Hight_Accuracy,
                    gpsFirst: true,
                    HttpTimeOut: 30000,
                    interval: 2000,
                    needAddress: true,
                    onceLocation: false,
                    onceLocationLatest: false,
                    locationProtocol: LocationProtocolEnum.HTTP,
                    sensorEnable: true,
                    wifiScan: true,
                    locationCacheEnable: true
                }, iosOption: {
                    desiredAccuracy: DesiredAccuracyEnum.kCLLocationAccuracyBest,
                    pausesLocationUpdatesAutomatically: 'YES',
                    allowsBackgroundLocationUpdates: 'NO',
                    locationTimeout: 10,
                    reGeocodeTimeout: 5,
                }
            })
        }
    }

    public static startLocation(positionUpdate: (postion: PositionRes) => void) {
        GaoDeLocation.startSerialLocation({
            androidOption: {
                locationMode: LocationModeEnum.Hight_Accuracy,
                gpsFirst: false,
                HttpTimeOut: 30000,
                interval: 2000,
                needAddress: true,
                onceLocation: false,
                onceLocationLatest: false,
                locationProtocol: LocationProtocolEnum.HTTP,
                sensorEnable: false,
                wifiScan: true,
                locationCacheEnable: true
            }, iosOption: {
                desiredAccuracy: DesiredAccuracyEnum.kCLLocationAccuracyBest,
                pausesLocationUpdatesAutomatically: 'YES',
                allowsBackgroundLocationUpdates: 'NO',
                locationTimeout: 10,
                reGeocodeTimeout: 5,
            }
        }).subscribe((positionRes) => {
            positionUpdate(positionRes)
        });
    }

    public static async stopLocation() {
        await GaoDeLocation.stopSerialLocation();
    }
}
