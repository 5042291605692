
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter
} from '@ionic/vue';
import {computed, defineComponent, getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {add, arrowBack, checkmark, chevronDownCircleOutline, trash} from "ionicons/icons";
import {doAuditCommit, getAuditChildList} from "@/api/nucleic"
import {RefresherCustomEvent} from "@ionic/core/dist/types/components/refresher/refresher-interface";
import {useStore} from "vuex";
import {UIUtils} from "@/common/UIUtils";
import {Filters} from "@/common/Filters";
import {Constants} from "@/common/Constants";
import {UserInfo} from "@/vo/UserInfo";
import {NucleicTemperInfo} from "@/vo/NucleicTemperVO";
import {NucleicAuditVO} from "@/vo/NucleicAuditVO";
import {NucleicAuditListVO} from "@/vo/NucleicAuditListVO";
import NucleicAuditDetailSliderPage from "@/views/nucleic/audit/NucleicAuditDetailSliderPage.vue";
import NucleicEditPage from "@/views/nucleic/report/NucleicEditPage.vue";
import NucleicAbnormalModal from "@/views/component/NucleicAbnormalModal.vue";
import InputModal from "@/views/component/InputModal.vue";

export default defineComponent({
  components: {
    IonPage,
    IonItemOptions,
    IonItemOption,
    IonIcon,
    IonItemSliding,
    IonRefresher,
    IonRefresherContent,
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonLabel,
    IonItem,
    IonList,
    IonImg,
    IonContent,
    NucleicAbnormalModal,
    InputModal,
  },
  props: {
    type: {type: Number}
  },
  setup(props: any) {
    console.log(props)
    console.log("props.modalId = " + props.modalId)

    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      deptId: string,
      isDisabled: boolean,
      list: NucleicAuditListVO,
      user: UserInfo,
      abnormalModalOpen: boolean,
      abnormalModalType: number,
      abnormalModalDeptId?: string,
      remarkModalOpen: boolean,
      remark?: string,
      currentDeptId?: string,
      remarkViewFlag: boolean,
      event?: Event,
    } = {
      deptId: store.getters["audit/lastDeptId"],
      isDisabled: false,
      list: new NucleicAuditListVO("", [], []),
      user: store.getters['user/userInfo'],
      abnormalModalOpen: false,
      abnormalModalType: 0,
      abnormalModalDeptId: undefined,
      remarkModalOpen: false,
      remark: undefined,
      currentDeptId: undefined,
      remarkViewFlag: false,
      event: undefined,
    }

    const state = reactive(temp);

    const viewFlagFunc = () => {
      return parseInt(props.type) !== Constants.ChildListType.UNCOMMIT
    }

    const viewFlagChildFunc = () => {
      return parseInt(props.type) === Constants.ChildListType.AUDIT
    }

    const viewFlagCalc = computed(() => {
      return viewFlagFunc()
    })

    const openAuditUnit = async (vo: NucleicAuditVO) => {
      const viewFlag = viewFlagFunc()
      await UIUtils.showModal(NucleicEditPage, {title: viewFlag ? '查看' : '编辑', item: vo, viewFlag: viewFlag})
      await doRefresh(null)
    }

    const openAuditTemper = async (vo: NucleicTemperInfo, index: number) => {
      if (vo) {
        const viewFlag = viewFlagChildFunc()
        await UIUtils.showModal(NucleicAuditDetailSliderPage, {title: viewFlag ? '查看' : '审核', index, viewFlag, auditItems: state.list})
        await doRefresh(null)
      }
    }

    const openAuditChild = async (vo: NucleicAuditVO) => {
      store.commit('audit/pushDeptId', {deptId: vo.reportUnitId, deptName: vo.reportUnitName})
      await doRefresh(null)
    }

    const closeSliding = () => {
      for (let i = 0; i < state.list.auditItems.length; i++) {
        if (proxy.$refs['sliding_0_' + i]) {
          proxy.$refs['sliding_0_' + i][0].$el.close()
        }
      }

      for (let i = 0; i < state.list.temperItems.length; i++) {
        if (proxy.$refs['sliding_1_' + i]) {
          proxy.$refs['sliding_1_' + i][0].$el.close()
        }
      }
    }

    const submitTemper = () => {
      console.log("submitTemper")
    }

    const submitAudit = async (deptId: string) => {
      try {
        await doAuditCommit(deptId, store.getters["audit/topDeptId"], false)
        doRefresh(null)
      } catch (e: any) {
        if (e.code && e.code === Constants.ApiCode.ERROR_NUCLEIC_NOT_REPORT_IS_NOT_EMPTY) {
          UIUtils.showAlertConfirm("确认", e.msg + ", 依然要提交?", async () => {
            //打开备注页
            state.currentDeptId = deptId
            state.remarkModalOpen = true
          }, () => {
            console.info("不提交")
          })
          console.error(e)
        } else {
          UIUtils.showToast("", e.message, 2000)
        }
      }
    }

    const fetchData = async () => {
      state.deptId = store.getters["audit/lastDeptId"]
      //只有新创建的任务可以通过普通方法查询任务列表，提交后的任务需要做权限过滤
      const data = await getAuditChildList(state.deptId, store.getters["audit/topDeptId"], props.type)
      return data
    }

    const openAbnormalModal = (type: number, item: NucleicAuditVO) => {
      console.log("openAbnormalModal")
      state.abnormalModalDeptId = item.reportUnitId
      state.abnormalModalOpen = true
      state.abnormalModalType = type
    }

    const doRefresh = async (event: RefresherCustomEvent | null) => {
      try {
        state.isDisabled = false
        state.list = await fetchData()
        closeSliding()
      } finally {
        if (event) {
          event.target.complete()
        }

      }
    }

    const auditIsNotEmpty = (item: NucleicAuditListVO) => {
      return item.auditItems.length > 0 || item.temperItems.length > 0;
    }

    const openRemark = (item: NucleicAuditVO) => {
      state.remarkModalOpen = true
      state.remarkViewFlag = true
      state.remark = item.remark
    }

    const onRemarkClose = async (flag: number) => {
      state.remarkModalOpen = false
      state.remarkViewFlag = false
      if (flag === 1) {
        if (state.currentDeptId) {
          try {
            await doAuditCommit(state.currentDeptId, store.getters["audit/topDeptId"], true, state.remark)
            doRefresh(null)
            state.remark = undefined
          } catch (e2: any) {
            UIUtils.showToast("", e2.message, 2000)
          }
        }
      } else {
        state.remark = undefined
        console.warn("state.currentDeptId 为空")
      }
    }

    onMounted(async () => {
      await doRefresh(null)
    })

    //此处页面刷新不生效，需要在tabs页监听
    onIonViewDidEnter(async () => {
      await doRefresh(null)
    })

    return {
      ...toRefs(state),
      arrowBack, add, trash,
      chevronDownCircleOutline,
      checkmark,
      openAuditUnit,
      openAuditTemper,
      submitAudit,
      submitTemper,
      doRefresh,
      auditIsNotEmpty,
      openAuditChild,
      openAbnormalModal,
      onRemarkClose,
      openRemark,
      statusNameCalc: Filters.getCleanStatusName,
      viewFlagCalc,
      timeFormatCalc: Filters.timeFormatCalc,
      NucleicStatus: Constants.NucleicReportStatus,
      nucleicReportStatusName: Filters.getNucleicReportStatusName,
      nucleicAuditStatusNameCalc: Filters.getNucleicAuditStatusName,
    }
  }
})
