
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter,
  TabsCustomEvent,
  useIonRouter
} from '@ionic/vue';
import {alertCircleOutline, arrowBack, checkmarkDoneOutline, closeCircleOutline, closeOutline, pencilOutline, searchOutline} from 'ionicons/icons'
import {NaviUtils} from "@/common/NaviUtils"
import {computed, getCurrentInstance, reactive, toRefs} from "vue"
import {useStore} from "vuex"
import NucleicTemperSearchModal from "./NucleicTemperSearchModal.vue"
import {NucleicTemperInfo} from "@/vo/NucleicTemperVO";
import {useRouter} from "vue-router";

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonIcon,
    IonTabBar,
    IonTabs,
    IonLabel,
    IonTabButton,
    IonRouterOutlet,
    IonBadge,
    IonButton,
    IonButtons,
    NucleicTemperSearchModal,
  },
  props: {
    modalId: {
      type: String
    },
  },
  setup() {
    const {proxy}: any = getCurrentInstance()
    const ionRouter = useIonRouter()
    const store = useStore()
    let temp: {
      tabs: any,
      childTab: any,
      deptName: string,
      modalOpenFlag: boolean,
      event?: Event,
    } = {
      tabs: null,
      childTab: null,
      deptName: store.getters["audit/lastDeptName"],
      modalOpenFlag: false,
      event: undefined,
    }

    const state = reactive(temp);

    const back = () => {
      store.commit('audit/popDeptId')
      const deptIds = store.getters["audit/deptIds"]
      if (deptIds.length === 0) {
        NaviUtils.navReplace('/nucleic/audit/list/0')
      } else {
        refreshCurrent()
      }
    }

    const close = () => {
      store.commit('audit/clearDeptId')
      NaviUtils.navReplace('/nucleic/audit/list/0')
    }

    const beforeTabChange = async (event: TabsCustomEvent) => {
      console.log(event)
    }

    const afterTabChange = async (event: TabsCustomEvent) => {
      console.log(event)
    }

    const refreshCurrent = () => {
      if (state.childTab) {
        state.childTab.components.forEach((component: any) => {
          if (state.childTab.injectedRoute.path === component.pathname) {
            component.vueComponentRef._value.doRefresh(null)
          }
        })
      }
    }

    const search = () => {
      state.modalOpenFlag = true
    }

    const onSearchModalClose = async (item: NucleicTemperInfo) => {
      console.log("onSearchModalClose", item)

      state.modalOpenFlag = false
    }

    store.watch((state, getters) => {
      console.log("store watch")
      return getters["audit/lastDeptName"]
    }, (n, v) => {
      state.deptName = n
    })

    onIonViewDidEnter(() => {
      console.log("tabs onIonViewDidEnter", state)
    })

    const processableCountCalc = computed(() => {

      return 0
    })

    const processingCountCalc = computed(() => {

      return 0
    })

    return {
      ...toRefs(state),
      arrowBack,
      checkmarkDoneOutline,
      alertCircleOutline,
      pencilOutline,
      closeCircleOutline,
      closeOutline,
      searchOutline,
      back,
      search,
      close,
      beforeTabChange,
      afterTabChange,
      onSearchModalClose,
      processableCountCalc,
      processingCountCalc,
    }
  }
}
