
import {
  InfiniteScrollCustomEvent,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter
} from '@ionic/vue';
import {NaviUtils} from "@/common/NaviUtils";
import {computed, defineComponent, getCurrentInstance, reactive, toRefs} from "vue";
import {add, arrowBack, chevronDownCircleOutline, trash} from "ionicons/icons";
import {doDelete, getList} from "@/api/nucleic"
import {RefresherCustomEvent} from "@ionic/core/dist/types/components/refresher/refresher-interface";
import {useStore} from "vuex";
import {UIUtils} from "@/common/UIUtils";
import {Filters} from "@/common/Filters";
import {Constants} from "@/common/Constants";
import {UserInfo} from "@/vo/UserInfo";
import {NucleicTemperInfo} from "@/vo/NucleicTemperVO";
import NucleicEditPage from "@/views/nucleic/report/NucleicEditPage.vue";

export default defineComponent({
  components: {
    IonPage,
    IonItemOptions,
    IonItemOption,
    IonFab,
    IonFabButton,
    IonIcon,
    IonItemSliding,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonLabel,
    IonItem,
    IonList,
    IonImg,
    IonContent,
  },
  props: {
    type: {
      type: Number, default: null
    },
  },
  setup(props: any) {
    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      pageNo: number,
      pageSize: number,
      isDisabled: boolean,
      list: Array<NucleicTemperInfo>,
      user: UserInfo,
      event?: Event,
    } = {
      pageNo: 1,
      pageSize: 10,
      isDisabled: false,
      list: [],
      user: store.getters['user/userInfo'],
      event: undefined,
    }

    const state = reactive(temp);

    const back = () => {
      NaviUtils.navPop();
    }

    const viewFlagFunc = (item: NucleicTemperInfo) => {
      return item.status == Constants.NucleicReportStatus.REJECT || item.status > Constants.NucleicReportStatus.UNCOMMIT
    }

    const viewFlagCalc = computed(() => {
      return (item: NucleicTemperInfo) => viewFlagFunc(item)
    })

    const openEdit = async (vo?: NucleicTemperInfo) => {
      if (vo) {
        const viewFlag = viewFlagFunc(vo)
        await UIUtils.showModal(NucleicEditPage, {title: viewFlag ? '查看' : '编辑', item: vo, isNew: false})
        await doRefresh(null)
      } else {
        await UIUtils.showModal(NucleicEditPage, {title: '新增', item: vo, isNew: true})
        await doRefresh(null)
      }
    }

    const deleteItem = async (item: NucleicTemperInfo) => {
      UIUtils.showAlertConfirm("删除", "是否确认删除?", async () => {
        try {
          if (item.id) {
            await doDelete([item.id])
            await doRefresh(null)
          }
        } catch (e: any) {
          console.error(e)
          UIUtils.showToast("", e.message, 2000)
        }
      }, () => {
        console.log("取消")
      })
    }

    const fetchData = async () => {
      //只有新创建的任务可以通过普通方法查询任务列表，提交后的任务需要做权限过滤
      const {total, list} = await getList(state.pageNo, state.pageSize, props.type)
      return list
    }
    const doRefresh = async (event: RefresherCustomEvent | null) => {
      try {
        state.pageNo = 1
        state.pageSize = 10
        state.isDisabled = false
        state.list = await fetchData()
      } finally {
        if (event) {
          event.target.complete()
        }

      }
    }

    const doInfinite = async (event: InfiniteScrollCustomEvent) => {
      try {
        state.pageNo++
        const data = await fetchData()
        if (data.length === 0) {
          state.isDisabled = true;
        } else {
          state.list.push(...data)
        }
      } finally {
        await event.target.complete()
      }
    }

    //此处页面刷新不生效，需要在tabs页监听
    onIonViewDidEnter(async () => {
      await doRefresh(null)
    })

    return {
      ...toRefs(state),
      arrowBack, add, trash,
      chevronDownCircleOutline,
      openEdit,
      deleteItem,
      doRefresh,
      doInfinite,
      back,
      statusNameCalc: Filters.getCleanStatusName,
      viewFlagCalc,
      timeFormatCalc: Filters.timeFormatCalc,
      NucleicStatus: Constants.NucleicReportStatus,
      nucleicStatusNameCalc: Filters.getNucleicReportStatusName,
      nucleicAuditStatusNameCalc: Filters.getNucleicAuditStatusName,
    }
  }
})
