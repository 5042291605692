// const HOST = process.env.API_HOST ? process.env.API_HOST : 'http://api-prevent.myectech.cn'
// const HOST = 'http://localhost:8104'
// const HOST = 'http://192.168.3.5:8104'
// const HOST = 'http://bcs-prevent.bdia.com.cn:8080'
// const HOST = 'http://192.168.3.6:8104'
// const LOGIN_HOST = 'http://sys-prevent.myectech.cn'
// const LOGIN_HOST = 'http://pkx.sys.adtecmall.com'
// const LOGIN_HOST = 'http://192.168.3.5:8101'
// const LOGIN_HOST = 'http://192.168.3.6:8101'
// const LOGIN_HOST = 'http://localhost:8101'
// const LOGIN_HOST = 'http://bcs-prevent.bdia.com.cn:8080'


export class API {
    static UPDATE_SERVER = "https://api-prevent.myectech.cn";
    static APP_ID = "prevent-app";

    static HOST = process.env.VUE_APP_API_HOST ? process.env.VUE_APP_API_HOST : 'https://api-prevent.myectech.cn';
    static LOGIN = process.env.VUE_APP_LOGIN_HOST + '/api/sys/oauth/login';
    static UPDATE_PASS = process.env.VUE_APP_LOGIN_HOST + '/api/sys/accounts/{accountId}/updatepass';
    static LOGOUT = process.env.VUE_APP_LOGIN_HOST + '/api/sys/oauth/revoke_token';
    static GET_USER = process.env.VUE_APP_LOGIN_HOST + '/api/sys/user/info';
    static UPLOAD_PIC = API.HOST + '/api/prevent/common/uploadFile';
    static UPLOAD_PIC_DATA_URL = API.HOST + '/api/prevent/common/uploadDataUrl';
    static INOUT_REPORT_ITEMS = API.HOST + '/api/prevent/inout/getReportItems';
    static JOB_REPORT_ITEMS = API.HOST + '/api/prevent/job/getReportItems';
    static GET_USER_LIST = API.HOST + '/api/prevent/common/getUserList';
    static GET_HOME_BADGE = API.HOST + '/api/prevent/common/getHomeBadge';
    static GET_HOME_MENU = API.HOST + '/api/prevent/common/getHomeMenu';
    static GET_REGEO_INFO = API.HOST + '/api/prevent/common/regeoInfo';
    static INOUT_REPORT = API.HOST + '/api/prevent/inout/oper';
    static GET_USER_INFO = API.HOST + '/api/prevent/inout/getUserInfo';
    static GET_JOB_LIST = API.HOST + '/api/prevent/job/user/getList';
    static GET_FLOWCHART_CONFIG_STRING = API.HOST + '/api/prevent/job/user/getFlowChartConfigString';
    static JOB_REPORT = API.HOST + '/api/prevent/job/user/report';
    static DIC_INFO_LIST = API.HOST + '/api/prevent/common/getDicList';
    static MATERIAL_APPLY = API.HOST + '/api/prevent/material/materialApply';
    static GET_MATERIAL_VIEW_ITEMS = API.HOST + '/api/prevent/material/getMaterialReportItems';
    static GET_MATERIAL_APPLY_REPORT = API.HOST + '/api/prevent/material/getMaterialApplyReport';
    static GET_MATERIAL_LIST = API.HOST + '/api/prevent/material/getMaterialApplyList';
    static GET_MATERIAL_PAGE_BUTTON = API.HOST + '/api/prevent/material/getMaterialProcessButtons';
    static GET_PROCESSABLE_MATERIAL_LIST = API.HOST + '/api/prevent/material/getProcessableMaterialApplyList';
    static GET_REPORT_DEFITEM_BY_REPORTER = API.HOST + '/api/prevent/common/getReportDefItemByReporter';
    static CHANGE_PASSWORD = API.HOST + '/api/prevent/common/changePassword';
    static GET_FULL_REPORT_ITEMS = API.HOST + '/api/prevent/common/getFullReportItem';
    static GET_BASIC_DATA_LIST = API.HOST + '/api/prevent/common/getBasicDataList';
    static GET_ROOM_LIST = API.HOST + '/api/prevent/common/getRoomList';
    static GET_VEHICLE_LIST = API.HOST + '/api/prevent/common/getVehicleList';
    static GET_ROOMCLEAN_LIST = API.HOST + '/api/prevent/clean/room/getListByUser';
    static GET_ROOMCLEAN_COUNT = API.HOST + '/api/prevent/clean/room/getCountByUser';
    static DELETE_ROOMCLEAN = API.HOST + '/api/prevent/clean/room/doDelete';
    static SAVE_ROOMCLEAN = API.HOST + '/api/prevent/clean/room/doEdit';
    static GET_ROOMCLEAN_LAST_REPORT = API.HOST + '/api/prevent/clean/room/getRoomCleanLastReport';
    static GET_VEHICLECLEAN_LIST = API.HOST + '/api/prevent/clean/vehicle/getListByUser';
    static GET_VEHICLECLEAN_COUNT = API.HOST + '/api/prevent/clean/vehicle/getCountByUser';
    static DELETE_VEHICLECLEAN = API.HOST + '/api/prevent/clean/vehicle/doDelete';
    static SAVE_VEHICLECLEAN = API.HOST + '/api/prevent/clean/vehicle/doEdit';
    static GET_VEHICLECLEAN_LAST_REPORT = API.HOST + '/api/prevent/clean/vehicle/getVehicleCleanLastReport';
    static GET_TOOLSCLEAN_LIST = API.HOST + '/api/prevent/clean/tools/getListByUser';
    static GET_TOOLSCLEAN_COUNT = API.HOST + '/api/prevent/clean/tools/getCountByUser';
    static DELETE_TOOLSCLEAN = API.HOST + '/api/prevent/clean/tools/doDelete';
    static SAVE_TOOLSCLEAN = API.HOST + '/api/prevent/clean/tools/doEdit';
    static GET_TOOLSCLEAN_LAST_REPORT = API.HOST + '/api/prevent/clean/tools/getToolsCleanLastReport';
    static APP_CHECK_UPDATE = API.HOST + '/api/prevent/app/checkUpdate';
    static REGIST_TOKEN = API.HOST + '/api/prevent/notification/registToken';
    static GET_NUCLEIC_LIST = API.HOST + '/api/prevent/nucleic/getListByUser';
    static GET_LAST_NUCLEIC_TEMPER = API.HOST + '/api/prevent/nucleic/getLastNucleicTemper';
    static NUCLEIC_REPORT_ITEMS = API.HOST + '/api/prevent/nucleic/getReportItems';
    static DELETE_NUCLEIC = API.HOST + '/api/prevent/nucleic/doDelete';
    static SAVE_NUCLEIC = API.HOST + '/api/prevent/nucleic/doEdit';
    static UPDATE_TABLE_SIGN = API.HOST + '/api/prevent/data/updateTableSign';

    static GET_NUCLEIC_AUDIT_LIST = API.HOST + '/api/prevent/nucleic/getAuditList';
    static GET_NUCLEIC_AUDIT_CHILD_LIST = API.HOST + '/api/prevent/nucleic/getAuditChildList';
    static GET_NUCLEIC_DO_TEMPER_AUDIT = API.HOST + '/api/prevent/nucleic/doTemperAudit';
    static GET_NUCLEIC_DO_AUDIT_COMMIT = API.HOST + '/api/prevent/nucleic/doAuditCommit';
    static GET_NUCLEIC_STATS_DATA = API.HOST + '/api/prevent/nucleic/getStatsData';
    static SEARCH_NUCLEIC_BY_USER = API.HOST + '/api/prevent/nucleic/searchByUser';
    static GET_NUCLEIC_AUDIT_COUNT = API.HOST + '/api/prevent/nucleic/getAuditCount';
    static GET_NUCLEIC_AUDIT_CHILD_COUNT = API.HOST + '/api/prevent/nucleic/getAuditChildCount';

    static GET_SUSPEND_LIST = API.HOST + '/api/prevent/nucleic/suspend/getListByUser';
    static GET_SUSPEND_COUNT = API.HOST + '/api/prevent/nucleic/suspend/getCountByUser';
    static DELETE_SUSPEND = API.HOST + '/api/prevent/nucleic/suspend/doDelete';
    static SAVE_SUSPEND = API.HOST + '/api/prevent/nucleic/suspend/doEdit';
    static GET_SUSPEND_AUDIT_LIST = API.HOST + '/api/prevent/nucleic/suspend/getAuditList';
    static GET_SUSPEND_AUDIT_COUNT = API.HOST + '/api/prevent/nucleic/suspend/getAuditCount';
    static GET_SUSPEND_DO_AUDIT = API.HOST + '/api/prevent/nucleic/suspend/doAudit';

    static GET_ISOLATION_APPLY_LIST_BY_USER = API.HOST + '/api/prevent/isolation/getListByUser';
    static GET_ISOLATION_APPLY_COUNT_BY_USER = API.HOST + '/api/prevent/isolation/getCountByUser';
    static DELETE_ISOLATION_APPLY = API.HOST + '/api/prevent/isolation/doDelete';
    static SAVE_ISOLATION_APPLY = API.HOST + '/api/prevent/isolation/doEdit';
    static GET_ISOLATION_AUDIT_LIST = API.HOST + '/api/prevent/isolation/getAuditList';
    static GET_ISOLATION_AUDIT_COUNT = API.HOST + '/api/prevent/isolation/getAuditCount';
    static DO_ISOLATION_AUDIT = API.HOST + '/api/prevent/isolation/doAudit';
}
