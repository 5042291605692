
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
  pickerController,
} from '@ionic/vue';
import {arrowBack, scanOutline} from 'ionicons/icons';
import {computed, getCurrentInstance, onMounted, reactive, toRefs, watch} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {doEdit, getLastNucleicTemper} from "@/api/nucleic"
import {UIUtils} from "@/common/UIUtils";
import PhotoUploader from "@/views/component/PhotoUploader.vue";
import {Filters} from "@/common/Filters";
import {NucleicTemperInfo} from "@/vo/NucleicTemperVO";
import {PickerColumnOption} from "@ionic/core/dist/types/components/picker/picker-interface";
import {DateUtils} from "@/common/DateUtils";
import {PictureInfoType} from "@/common/TypeDefs";
import {Constants} from "@/common/Constants";
import {ReportDefItemWithValue} from "@/vo/ReportDefItemWithValue";
import DynamicForm from "@/views/component/DynamicForm.vue";
import GeoLocator from "@/views/component/GeoLocator.vue";
import {PositionRes} from "@awesome-cordova-plugins/gao-de-location";

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonTextarea,
    IonInput,
    IonLabel,
    IonButtons,
    IonButton,
    IonIcon,
    IonAccordion,
    IonAccordionGroup,
    IonSelect,
    IonSelectOption,
    IonCol,
    IonRow,
    IonGrid,
    IonFooter,
    IonDatetimeButton,
    IonDatetime,
    IonModal,
    PhotoUploader,
    DynamicForm,
    GeoLocator
  }
  ,
  props: {
    params: {
      item: Object,
      viewFlag: Boolean,
      isNew: Boolean,
    }
  },
  setup(props: any) {
    console.log("props.modalId = " + props.modalId)
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    let temp: {
      pageItems: Array<ReportDefItemWithValue>,
      form: NucleicTemperInfo,
      detectTimeString?: string,
      detectResultFlag: boolean,
      unDetectReasonFlag: boolean,
      notDetectRemarkFlag: boolean,
      nucleicUnfinishReasonFlag: boolean,
      nucleicValidFlag: boolean,
      detectImgFlag: boolean,
      detectImgArray: Array<PictureInfoType>,
      notDetectRemarkRef: any,
      openAccordionList: Array<string>,
      event?: Event,
    } = {
      pageItems: [],
      form: (props.params && props.params.item) ? props.params.item : {},
      detectTimeString: undefined,
      detectResultFlag: false,
      nucleicUnfinishReasonFlag: true,
      detectImgFlag: true,
      unDetectReasonFlag: true,
      notDetectRemarkFlag: true,
      nucleicValidFlag: false,
      detectImgArray: (props.params && props.params.item && props.params.item.detectImg ? JSON.parse(props.params.item.detectImg) : []),
      notDetectRemarkRef: null,
      openAccordionList: ['temper'],
      event: undefined,
    }

    if (!temp.form.temper1Time) {
      temp.form.temper1Time = DateUtils.parseTimeString("09:00", "HH:mm")
    }
    if (!temp.form.temper2Time) {
      temp.form.temper2Time = DateUtils.parseTimeString("14:00", "HH:mm")
    }

    const state = reactive(temp);

    const temperTimeSet = (index: number, timer?: number) => {
      openPicker((ts) => {
        if (index == 1) {
          state.form.temper1Time = ts
        } else if (index == 2) {
          state.form.temper2Time = ts
        }
      }, timer)
    }

    const back = () => {
      console.log("back")
      UIUtils.dismissModal(props.modalId);
    }

    const save = async (flag: number) => {
      state.form.detectImg = state.detectImgArray.length > 0 ? JSON.stringify(state.detectImgArray) : undefined

      const msg = validation()
      if (msg) {
        UIUtils.showToast("", msg, 2000)
        return
      }

      let confirmMsg = ""
      const originStatus = state.form.status
      if (flag === 1) {
        confirmMsg = "是否确认提交?"
        state.form.status = Constants.NucleicReportStatus.TEAM_AUDIT
      } else {
        //表示仅保存，需要将状态置为未提交
        state.form.status = Constants.NucleicReportStatus.UNCOMMIT
      }

      // const report = proxy.$refs['dynamicFormId'].getReportData()
      // if (typeof report !== 'string') {
      //   state.form.reportValues = JSON.stringify(report.data)
      // } else {
      //   state.form.reportValues = JSON.stringify(JSON.parse(report).data)
      // }
      UIUtils.showAlertConfirm("确认", confirmMsg, async () => {

        try {
          await doEdit(state.form)
          back()
        } catch (e: any) {
          state.form.status = originStatus
          console.error(e)
          UIUtils.showToast("", e.message, 2000)
        }
      }, () => {
        console.log("取消")
        state.form.status = originStatus
      }, "提交", "取消")
    }

    const validation = () => {
      const temp1Time = DateUtils.fromTs(state.form.temper1Time, 'HH:mm')
      const temp2Time = DateUtils.fromTs(state.form.temper2Time, 'HH:mm')
      if (!state.form.temper1Value) {
        return "第一次体温不允许为空";
      }
      if (!state.form.temper2Value) {
        return "第二次体温不允许为空";
      }
      if (!state.form.address || state.form.address.trim() == '') {
        return "当前定位不允许为空";
      }
      if (state.form.nucleicValid !== 0 && state.form.nucleicValid !== 1) {
        return "是否有效期不允许为空";
      }
      if (state.form.nucleicToday == undefined) {
        return "请选择是否完成当日核酸";
      } else if (state.form.nucleicToday === 0 && (!state.form.nucleicUnfinishReason || state.form.nucleicUnfinishReason.trim() == '')) {
        return "请输入当日核酸未完成原因";
      }
      if (!state.form.detectType || state.form.detectType.trim() == '') {
        return "检测方式不允许为空";
      }
      if (state.form.detectType === '未检测') {
        if (!state.form.notDetectReason || state.form.notDetectReason.trim() == '') {
          return "未检测原因不允许为空";
        }
        if (state.form.notDetectReason === '其他' && (!state.form.notDetectRemark || state.form.notDetectRemark.trim() == '')) {
          return "请填写未检测原因说明";
        }
      } else {
        if (!state.form.detectResult || state.form.detectResult.trim() == '') {
          return "检测结果不允许为空";
        }
        if (state.form.detectResult !== '未出结果' && (!state.form.detectImg || state.form.detectImg.trim() == '')) {
          return "检测结果截图不允许为空";
        }
      }

      return null;
    }

    const refreshFlags = (value: string) => {
      if (value === '未检测') {
        state.form.detectDate = undefined
        state.detectTimeString = undefined
        state.form.detectResult = undefined

        state.detectResultFlag = true
        state.unDetectReasonFlag = false
        state.notDetectRemarkFlag = false
        state.detectImgFlag = false

        state.form.notDetectReason = store.getters["dic/notDetectReasonList"][0].value
      } else {
        if (!state.form.detectDate) {
          state.form.detectDate = DateUtils.getLastDay().getTime()
        }
        if (!state.detectTimeString) {
          state.detectTimeString = DateUtils.fromTs(state.form.detectDate, 'yyyy-MM-dd')
        }
        state.detectResultFlag = false
        state.unDetectReasonFlag = true
        state.notDetectRemarkFlag = true
        state.detectImgFlag = true
        state.form.notDetectReason = undefined
      }

      console.info("openAccordionList = ", state.openAccordionList)
    }
    const detectTypeChange = (event: CustomEvent) => {
      console.log(event)
      refreshFlags(event.detail.value)
    }

    const nucleicTodayChange = (event: CustomEvent) => {
      console.info(event)
      if (event.detail.value === 1) {
        state.nucleicUnfinishReasonFlag = true
        state.form.nucleicUnfinishReason = undefined
      } else if (event.detail.value === 0) {
        state.nucleicUnfinishReasonFlag = false
      }
    }

    const openPicker = async (callback: (ts: number) => void, timer?: number) => {
      const hours: PickerColumnOption[] = []
      const minutes: PickerColumnOption[] = []

      let hourIndex
      let minuteIndex
      if (timer) {
        hourIndex = parseInt(DateUtils.fromTs(timer, "HH"))
        minuteIndex = parseInt(DateUtils.fromTs(timer, "mm"))
      }
      for (let h = 0; h < 24; h++) {
        hours.push({text: h.toString(), value: h})
      }
      for (let m = 0; m < 60; m++) {
        minutes.push({text: m.toString(), value: m})
      }
      const picker = await pickerController.create({
        columns: [
          {
            name: 'hour',
            options: hours,
            selectedIndex: hourIndex,
          },
          {
            name: 'minute',
            options: minutes,
            selectedIndex: minuteIndex,
          },
        ],
        buttons: [
          {
            text: '取消',
            role: 'cancel',
          },
          {
            text: '确认',
            handler: (value) => {
              console.log(value)
              callback(DateUtils.parseTimeString(value.hour.value + ":" + value.minute.value, 'HH:mm'))
            },
          },
        ],
      });
      await picker.present();
    }

    const onLocation = (location: PositionRes) => {
      state.form.address = location.formattedAddress
      state.form.province = location.province
      state.form.city = location.city
      state.form.area = location.district
      state.form.street = location.street
      state.form.latitude = location.latitude
      state.form.longitude = location.longitude
    }

    const accordionChange = (e: any) => {
      if (e.target && e.currentTarget) {
        if (e.target.id === e.currentTarget.id) {
          state.openAccordionList = e.detail.value
        }
      }
    }

    watch(() => state.openAccordionList, (n, o) => {
      console.info("openAccordionList change", n, o)
    })

    onMounted(async () => {
      const intervallId = setInterval(async () => {
        //感觉是ionic的bug, textarea-wrapper的高度设置被设置为nativeInput的scrollHeight的高度, 但是scrollHeight高度为0, 导致控件显示不出来
        if (state.notDetectRemarkRef) {
          const textarea: HTMLTextAreaElement = await state.notDetectRemarkRef.$el.getInputElement()
          if (textarea) {
            if (textarea.parentElement) {
              textarea.parentElement.style.height = "auto"
            }
            textarea.style.height = "auto"
            console.log(textarea)

            clearInterval(intervallId)
          }
        }
      }, 1000)


      if (!state.form || !state.form.temper1Value) {
        const lastTemper = await getLastNucleicTemper()
        if (lastTemper) {
          state.form.temper1Value = lastTemper.temper1Value
          state.form.temper2Value = lastTemper.temper2Value
        }
      }
      // refreshReportExt()
    })

    refreshFlags(state.form.detectType)

    // const refreshReportExt = async () => {
    //   const id = state.form.id
    //   getReportItems(id, viewFlagCalc.value).then(async (items) => {
    //     state.pageItems = items
    //     await nextTick()
    //     await proxy.$refs['dynamicFormId'].refreshDic()
    //   }).catch((err) => {
    //     console.error(err)
    //     UIUtils.showToast("", "获取页面配置失败", 2000)
    //   })
    // }

    const viewFlagCalc = computed(() => {
      return props.params.isNew === false && state.form.status > Constants.NucleicReportStatus.UNCOMMIT
    })

    const lastReportCalc = computed(() => {
      if (state.form.lastDate) {
        return state.form.lastDate + '(' + state.form.lastDetectResult + ')' + ', 距今' + state.form.lastDays + '天'
      } else {
        return '未找到上次核酸信息'
      }
    })

    return {
      ...toRefs(state),
      scanOutline,
      arrowBack,
      back,
      save,
      temperTimeSet,
      detectTypeChange,
      nucleicTodayChange,
      detectTypeList: store.getters["dic/detectTypeList"],
      detectResultList: store.getters["dic/detectResultList"],
      notDetectReasonList: store.getters["dic/notDetectReasonList"],
      timeFormatCalc: Filters.timeFormatCalc,
      viewFlagCalc,
      lastReportCalc,
      onLocation,
      accordionChange,
      nucleicReportStatus: Constants.NucleicReportStatus
    }
  }
}
