
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter,
  TabsCustomEvent,
  useIonRouter
} from '@ionic/vue';
import {alertCircleOutline, arrowBack, checkmarkDoneOutline, closeCircleOutline, pencilOutline, searchOutline} from 'ionicons/icons';
import {NaviUtils} from "@/common/NaviUtils";
import {computed, getCurrentInstance, reactive, toRefs} from "vue";
import {useStore} from "vuex";
import NucleicTemperSearchModal from "./NucleicTemperSearchModal.vue"
import {NucleicTemperInfo} from "@/vo/NucleicTemperVO";
import {Filters} from "@/common/Filters";
import {Constants} from "@/common/Constants";

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonIcon,
    IonTabBar,
    IonTabs,
    IonLabel,
    IonTabButton,
    IonRouterOutlet,
    IonBadge,
    IonButton,
    IonButtons,
    NucleicTemperSearchModal,
  },
  setup() {
    const {proxy}: any = getCurrentInstance()
    const ionRouter = useIonRouter()
    const store = useStore()
    let temp: {
      tabs: any,
      childTab: any,
      modalOpenFlag: boolean,
      event?: Event,
    } = {
      tabs: null,
      childTab: null,
      modalOpenFlag: false,
      event: undefined,
    }

    const state = reactive(temp);

    const back = () => {
      NaviUtils.navReplace("/home")
    }

    const search = () => {
      state.modalOpenFlag = true
    }

    const beforeTabChange = async (event: TabsCustomEvent) => {
      console.log(event)
    }

    const afterTabChange = async (event: TabsCustomEvent) => {
      console.log(event)
    }

    const onSearchModalClose = async (item: NucleicTemperInfo) => {
      console.log("onSearchModalClose", item)

      state.modalOpenFlag = false
    }

    onIonViewDidEnter(() => {
      console.log("tabs onIonViewDidEnter", state)

      // state.childTab.components.forEach((component: any) => {
      //   if (state.childTab.injectedRoute.path === component.pathname) {
      //     component.vueComponentRef._value.doRefresh(null)
      //   }
      // })
    })

    const processableCountCalc = computed(() => {
      if (store.getters["nucleic/reportCount"]) {
        return Filters.statusNum(store.getters["nucleic/reportCount"], [Constants.HomeListType.TODO])
      }
      return 0
    })

    const processingCountCalc = computed(() => {
      if (store.getters["nucleic/reportCount"]) {
        return Filters.statusNum(store.getters["nucleic/reportCount"], [Constants.HomeListType.DONE])
      }
      return 0
    })

    return {
      ...toRefs(state),
      arrowBack,
      checkmarkDoneOutline,
      alertCircleOutline,
      pencilOutline,
      closeCircleOutline,
      searchOutline,
      search,
      back,
      beforeTabChange,
      afterTabChange,
      onSearchModalClose,
      processableCountCalc,
      processingCountCalc,
    }
  }
}
