
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';
import {arrowBack, scanOutline} from 'ionicons/icons';
import {computed, getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import PhotoUploader from "@/views/component/PhotoUploader.vue";
import VideoUploader from "@/views/component/VideoUploader.vue";
import {doEdit, getLastReport} from "@/api/roomclean"
import {UIUtils} from "@/common/UIUtils";
import {RoomCleanVO} from "@/vo/RoomCleanVO";
import RoomSelectionModal from "@/views/component/RoomSelectionModal.vue"
import {RoomInfoVO} from "@/vo/RoomInfoVO";
import {BarcodeScanner, BarcodeScanResult} from "@awesome-cordova-plugins/barcode-scanner";
import {format, parse} from "date-fns";
import {Constants} from "@/common/Constants";
import {Filters} from "@/common/Filters";
import {ComboInfo} from "@/vo/ComboInfo";
import {ArrayUtils} from "@/common/ArrayUtils";
import {QRCodeVO} from "@/vo/QRCodeVO";

export default {
  components: {
    IonImg,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonTextarea,
    IonInput,
    IonLabel,
    IonButtons,
    IonButton,
    IonIcon,
    IonCol,
    IonRow,
    IonGrid,
    IonFooter,
    IonAccordion,
    IonAccordionGroup,
    IonSelect,
    IonSelectOption,
    RoomSelectionModal,
    PhotoUploader,
    VideoUploader
  }
  ,
  props: {
    params: {
      type: Object
    },
    modalId: {
      type: String
    },
  }
  ,
  setup(props: any) {
    console.log("props.modalId = " + props.modalId)
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    let temp: {
      cleanTimeString: string,
      form: RoomCleanVO,
      roomSelectionOpen: boolean,
      disinfectorNameList: Array<ComboInfo>,
      disinfectorPercentList: Array<ComboInfo>,
      cleanTypeList: Array<ComboInfo>,
      event?: Event,
    } = {
      cleanTimeString: (props.params && props.params.item) ? format(new Date(props.params.item.cleanTime), 'yyyy-MM-dd HH:mm') : format(new Date(), 'yyyy-MM-dd HH:mm'),
      form: (props.params && props.params.item) ? props.params.item : {},
      roomSelectionOpen: false,
      disinfectorNameList: store.getters["dic/disinfectorNameList"],
      disinfectorPercentList: store.getters["dic/disinfectorPercentList"],
      cleanTypeList: store.getters["dic/cleanTypeList"],
      event: undefined,
    }
    const state = reactive(temp);

    const disinfectorNamePopoverOptions: any = {
      header: '消毒剂选择',
      // subHeader: '选择消毒剂种类',
      // message: '请选择一种消毒剂'
    };

    const back = () => {
      console.log("back")
      UIUtils.dismissModal(props.modalId);
    }

    const openRoomSelection = (item: RoomCleanVO) => {
      if (!props.params.viewFlag) {
        state.roomSelectionOpen = true;
      }
    }

    const save = async (flag: number) => {
      const msg = validation()
      if (msg) {
        UIUtils.showToast("", msg, 2000)
        return
      }

      let confirmMsg = ""
      if (flag === 0) {
        confirmMsg = "是否确认开始消毒?"
      } else if (flag === 1) {
        confirmMsg = "是否确认提交消毒数据?"
      }

      UIUtils.showAlertConfirm("确认", confirmMsg, async () => {
        const date = parse(state.cleanTimeString, 'yyyy-MM-dd HH:mm', new Date())
        state.form.cleanTime = date.getTime()
        const originValue = state.form.status
        if (flag === 0) {
          state.form.status = Constants.CleanStatus.BEGIN
        } else if (flag === 1) {
          state.form.status = Constants.CleanStatus.COMMIT
        }

        const disinfectorItem: ComboInfo = ArrayUtils.findItem(state.disinfectorNameList, "value", state.form.disinfectorId)
        const disinfectorPercentItem: ComboInfo = ArrayUtils.findItem(state.disinfectorPercentList, "value", state.form.disinfectorPercentId)
        state.form.disinfectorName = disinfectorItem.label
        state.form.disinfectorPercentName = disinfectorPercentItem.label
        state.form.pictureInfo = JSON.stringify(state.form.pictureList)
        state.form.videoInfo = JSON.stringify(state.form.videoList)
        try {
          await doEdit(state.form)
          back()
        } catch (e) {
          console.error(e)
          UIUtils.showToast("", "保存数据失败", 2000)
          state.form.status = originValue
        }
      }, () => {
        console.log("取消")
      }, "提交", "取消")
    }

    const roomSelectionClose = async (item: RoomInfoVO) => {
      console.log("roomSelectionClose", item)
      if (item) {
        await afterRoomSelect({id: item.id, name: item.roomName})
      }
      state.roomSelectionOpen = false
    }

    const afterRoomSelect = async (item: { id: string, name: string }) => {
      if (item) {
        if (fillRoomCleanInfo(item)) {
          const lastReport = await getLastReport(item.id)
          if (lastReport) {
            state.form = Object.assign({}, lastReport)
            state.form.id = undefined
            state.form.status = undefined
            state.form.pictureList = []
            state.form.videoList = []
            state.form.beginTime = undefined
            state.form.commitTime = undefined
            state.form.signatureDataUrl = undefined
          } else {
            console.warn("没有上一次上报信息")
          }
        }
      }
    }

    const fillRoomCleanInfo = (room: { id: string, name: string }) => {
      const roomList: Array<RoomInfoVO> = store.getters["selector/roomList"]
      if (roomList && roomList.length > 0 && roomList.some(item => item.id === room.id && item.roomName === room.name)) {
        state.form.areaName = room.name
        state.form.areaId = room.id
        // state.form.disinfectorId = room.disinfectorId
        // state.form.disinfectorName = room.disinfectorName
        // state.form.disinfectorPercentId = room.disinfectorPercentId
        // state.form.disinfectorPercentName = room.disinfectorPercentName
        return true
      } else {
        state.form.areaName = undefined
        state.form.areaId = undefined
        UIUtils.showToast("", "二维码无效, 请确认您有消毒权限", 2000)
        return false
      }
    }

    const openQRScan = () => {
      BarcodeScanner.scan().then(async (barcodeData: BarcodeScanResult) => {
        console.log('Barcode data', barcodeData);
        try {
          const room: QRCodeVO = JSON.parse(barcodeData.text)
          if (room && room.id && room.name) {
            await afterRoomSelect(room)
          } else {
            throw new Error("二维码无效")
          }
        } catch (e) {
          console.error(e)
          UIUtils.showToast("", "二维码无效,请联系管理员解决", 2000)
        }

      }).catch(err => {
        console.log('Error', err);
      });
    }

    const validation = () => {
      if (!state.form.areaId || !state.form.areaName) {
        return "区域不允许为空";
      }
      if (!state.form.cleanTarget || state.form.cleanTarget.trim() == '') {
        return "消毒对象不允许为空";
      }
      if (!state.form.disinfectorId) {
        return "消毒剂名称不允许为空";
      }
      if (!state.form.disinfectorPercentId) {
        return "消毒剂配比不允许为空";
      }
      if (!state.form.cleanType || state.form.cleanType.trim() == '') {
        return "消毒方式不允许为空";
      }
      if (!state.cleanTimeString || state.cleanTimeString.trim() == '') {
        return "消毒时间不允许为空";
      }
      if (!state.form.cleanTool || state.form.cleanTool.trim() == '') {
        return "消毒工具不允许为空";
      }
      return null;
    }

    const rejectedCalc = computed(() => {
      return state.form.status === Constants.CleanStatus.REJECT
    })

    onMounted(() => {
      if (props.params && props.params.item) {
        console.log("不打开二维码扫描")
      } else {
        openQRScan()
      }
    })
    return {
      ...toRefs(state),
      scanOutline,
      arrowBack,
      back,
      save,
      openRoomSelection,
      roomSelectionClose,
      openQRScan,
      rejectedCalc,
      CleanStatus: Constants.CleanStatus,
      timeFormatCalc: Filters.timeFormatCalc,
      cleanStatusCreateCalc: Filters.cleanStatusCreateCalc,
      disinfectorNamePopoverOptions,
    }
  }
}
